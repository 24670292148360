import React from 'react';
import styles from './fentanyl-facts.module.css'

export default function FentanylFacts(props) {
  return (
    <div className={styles.fentanylContainer}>
      <img src="https://www.1310news.com/wp-content/blogs.dir/sites/4/2016/06/23/Opiates.jpg" className={styles.fentanylImg}/>
      <div className={styles.textContainer}>
        <h3>Fentanyl</h3>
        <h4>Street Names</h4>
        <p>Apache, China Girl, China Town, Dance Fever, Friend, Goodfellas, Great Bear, He-Man, Jackpot, King Ivory, Murder 8, and Tango & Cash.</p>
      </div>
    </div>
  )
}
