import React, {useState} from 'react'
import {Link} from 'gatsby'
import styles from './expandable-content.module.css'


export default function ExpandableContent(props){
  const [open, setOpen] = useState(props.expand || false)
  return (
    <div className={styles.expandableContainer}>
      <div className={styles.titleContainer} onClick={() => setOpen(!open)}>
        <h3 className={styles.expandTitle}>{props.data.title} </h3>
        {open ? <span className={styles.expand}>&#9650;</span> : <span className={styles.expand}>&#9660;</span>}
      </div>
      <p className={open ? styles.showContent : styles.hideContent}>{props.data.body}</p>
    </div>
  )

}
