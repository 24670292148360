import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ContentBlock from '../components/content-block'
import SectionHeader from '../components/section-header';
import ExpandableContent from '../components/expandable-content';
import FentanylFacts from '../components/fentanyl-facts';

class FentanylPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const headerData = {
      title: 'Fentanyl Facts'
    }

    const content = {
      title: 'Fentanyl is a synthetic opioid that is approximately 100 times more potent than morphine and 50 times more potent than heroin.',
      body: 'Fentanyl is a potent synthetic opioid drug approved as a Schedule II narcotic by the Food and Drug Administration for use as an analgesic (pain relief) and anesthetic. In the past several years, it has become increasingly common for illicit and counterfeit drugs to be laced with fentanyl, often to a lethal extent. Illicit fentanyl is primarily manufactured in foreign labs, then smuggled into the United States through Mexico. The drug is then sold on the illegal drug market after being distributed throughout the country. Alongside illicit fentanyl that is being sold as such, there is also an increasing amount of other illicit drugs being sold that have been laced with fentanyl.'
    }

    const content2 = {
      title: 'Annual overdose deaths due to synthetic opioids have increased 6.5x in the past 6 years to nearly 70,000.',
      body: 'Overdose deaths due to synthetic opioids (primarily fentanyl) have increased from less than 10,000 in 2015 to nearly 65,000 in 2021. Synthetic opioids account for approximately 80% of the increase in total drug overdose deaths in the past 5 years, while overdoses to due to cocaine and heroin have remained nearly flat.'
    }

    const content3 = {
      title: 'Fentanyl is increasingly contaminating other illicit drugs - from cocaine, to marijuana, to counterfeit Xanax - often at fatal levels.',
      body: 'Many illegal drugs these days are intentionally being mixed with fentanyl because fentanyl is low cost, and increases the potency of other drugs. As fentanyl most often comes in powder form, it can be undetectable when mixed with cocaine or heroin. Increasingly, fentanyl is being pressed into pills made to look like legitimate prescription pills, presenting a potentially fatal mistake when consumed. As there is absolutely no quality control in the black market of selling illicit drugs, it is impossible for a consumer to know if their drugs are cut with fentanyl, and if so, how much.'
    }

    const content4 = {
      title: 'Contaminated pills are often sold to unsuspecting users on social media platforms like Snapchat, Instagram, and Twitter.',
      body: 'Dealers set up accounts on these platforms using anonymous phone numbers, and procure counterfeit or contaminated pills from drug cartels in Mexico. They use social media to provide potential buyers with different guidelines on how to exchange contact information using encrypted communications apps such as WhatsApp and Wickr. The user thinks they are buying Xanax, Oxycontin, Adderall, etc. But the DEA now claims that around 40% of the counterfeits they seize contain enough fentanyl to be fatal.'
    }

    return (
      <Layout location={this.props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={headerData} />
          <FentanylFacts />
          <ExpandableContent data={content} expand={true}/>
          <ExpandableContent data={content2} />
          <ExpandableContent data={content3} />
          <ExpandableContent data={content4} />
          <div style={{margin: '3% auto', padding: '30px', width: '60%', minWidth: '300px', textAlign: 'center', backgroundColor: '#F25C05', color: 'white', borderRadius: '10px'}}>
            <h3>The days of carefree recreational drug use are over. Every illicit drug someone tries is now a game of Russian roulette.</h3>
            <p style={{textAlign: 'center', maxWidth: '800px', margin: '3%auto'}}>These are not overdoses; they are poisonings. Nobody dies from taking a single uncontaminated Xanax or Percocet. These are fake pills, and one pill can kill.</p>
            <a href="https://www.dea.gov/onepill" target="_blank" style={{borderBottom: '1px solid'}}>Visit the DEA's website to learn more</a>
          </div>
      </Layout>
    )
  }
}

export default FentanylPage

export const pageQuery = graphql`
  query FentanylQuery {
    allContentfulContentBlock(sort: {order: ASC, fields: order} filter: {page: {eq:"/scholarship"}}) {
    edges {
      node {
        backgroundImage {
          file {
            url
          }
        }
        blockImage {
          file {
            url
          }
        }
        contentText {
          contentText
        }
        contentful_id
        title
        link
        linkText
        order
        page
        imagePosition
      }
    }
  }

  allContentfulAsset(filter: {contentful_id: {eq: "7aMyX9u58lkIai4l271R0a"}}) {
  edges {
    node {
      file {
        url
      }
      contentful_id
    }
  }
}
  }
`
